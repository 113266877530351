@import styles/sass/abstracts

.confirmDelete
  display: flex
  flex-direction: row
  align-items: center
  padding: 16px
  margin-bottom: 17px
  gap: $gap-sm-2
  background: $success-background
  +boxShadow
  .icon
    svg
      display: block
      +style-icon(16.76px, #075438)

  span
    font-weight: 400
    font-size: 12px
    line-height: 22.4px

.loginHead
  align-items: center

.section
  margin: auto
  background: $white
  padding: 48px 64px
  +res-sm-max
    padding: 30px 0
  +res-md-max
    width: auto
    margin: 0 5%

.register
  +typoTextSm
  text-align: center
  margin: 20px 0
  a
    font-weight: 600
    color: $accent-default

.head
  text-align: center
  margin-bottom: 30px

.title
  +font(36, 39, 700)
  margin-bottom: 16px
  +res-sm-max
    +font(24, 28, 700)

.description
  +font(18, 25, 400)
  +customContent


